<template>
  <div class="video-detail" v-if="info.video_url">
    <VueVideo
      v-if="isView"
      :src="info.video_url"
      :cover="info.video_img"
      :onTime="handleTime"
      :onLoaded="handleLoaded"
    />

    <div class="no-video" v-else>
      <img :src="videoAvatar" alt="" />
      <div class="body">
        <div>
          <div class="msg">
            <div>立刻解锁本课程</div>
            <div>开启PTE冲分模式</div>
          </div>

          <div class="btn">
            <template v-if="courseInfo.is_vip != 1">
              <el-button type="primary" @click="onPay">立刻购买</el-button>
            </template>
            <template v-else>
              <el-button type="primary" @click="handleVip">购买会员</el-button>
            </template>
          </div>
        </div>
      </div>
    </div>

    <VipTip ref="vipTip" />
  </div>
</template>

<script>
import VueVideo from '@/components/vue-video.vue'
import { throttle } from 'lodash'
import { getVideoAvatar } from '@/utils/video'
import VipTip from '../SkillDetails/vipTip'

import { apiCourseLearn } from '@/api/course'

export default {
  components: {
    VueVideo,
    VipTip
  },
  props: {
    info: {
      type: Object,
      default: () => ({})
    },
    courseInfo: {
      type: Object,
      default: () => ({})
    },
    isView: {
      type: Boolean,
      default: false
    },
    onPay: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      videoAvatar: null
    }
  },
  watch: {
    info: {
      deep: true,
      handler() {
        this.genAvatar()
      }
    }
  },
  methods: {
    async genAvatar() {
      this.videoAvatar = await getVideoAvatar(this.info.video_url, 710, 400)
    },
    handleLoaded({ target }) {
      // this.saveLearn(target.duration, target.currentTime)
    },
    handleTime({ target }) {
      this.timeSave(target.duration, target.currentTime)
    },
    timeSave: throttle(function(duration, currentTime) {
      this.saveLearn(duration, currentTime)
    }, 3000),
    async saveLearn(duration, currentTime) {
      await apiCourseLearn({
        course_id: this.info.course_id,
        chapter_id: this.info.id,
        video_time: Math.round(duration) || 1,
        learn_time: Math.round(currentTime) || 1
      })
    },

    handleVip() {
      this.$refs.vipTip.open()
    }
  }
}
</script>

<style scoped lang="scss">
.video-detail {
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  ::v-deep .vue-video {
    .vcp-container {
      background-color: #5c5c69;
      > video {
        // height: 400px;
      }
    }
  }

  .no-video {
    height: 400px;
    position: relative;
    > img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .body {
      position: relative;
      background-color: rgba(0, 0, 0, 0.8);
      width: 100%;
      height: 100%;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .msg {
      font-size: 18px;
      line-height: 30px;
      color: #fff;
      text-align: center;
      font-weight: bold;
    }
    .btn {
      text-align: center;
      margin-top: 24px;
      .el-button {
        width: 152px;
        height: 50px;
        background-color: #3b86ff;
        border-color: #3b86ff;
      }
    }
  }
}
</style>
