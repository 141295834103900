export const getVideoAvatar = (url, width, height) => {
  return new Promise((resolve) => {
    const videoEl = document.createElement('video')
    videoEl.style.width = `${width}px`
    videoEl.style.height = `${height}px`
    videoEl.autoplay = true
    videoEl.muted = true
    videoEl.setAttribute('crossOrigin', 'anonymous')
    videoEl.src = url

    videoEl.addEventListener('loadeddata', () => {
      setTimeout(() => {
        const dpr = window.devicePixelRatio || 1
        const canvasEl = document.createElement('canvas')
        canvasEl.style.position = 'fixed'
        canvasEl.width = videoEl.videoWidth * dpr
        canvasEl.height = videoEl.videoHeight * dpr
        canvasEl
          .getContext('2d')
          .drawImage(videoEl, 0, 0, canvasEl.width, canvasEl.height)

        const dataURL = canvasEl.toDataURL('image/jpg')
        resolve(dataURL)
      }, 500)
    })
  })
}
