<template>
  <el-dialog
    title="分享给老师"
    width="630px"
    :visible="visible"
    @close="visible = false"
  >
    <div class="content">
      <div>
        添加PTEGO小助手微信，进入PTEGO备考群。分享录音链接至群内，获得老师点评。
      </div>
      <img :src="helperInfo.site_val" alt="" />
      <div class="tip">{{helperInfo.remark}}</div>
      <div class="link-wrap">
        <div>录音链接</div>
        <div class="ipt-wrap">
          <el-input readonly :value="h5Link" />
          <el-button type="primary" @click="handleCopy">复制</el-button>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import copyText from '@/utils/clipboard'
export default {
  data() {
    return {
      visible: false,
      taskId: 0
    }
  },
  computed: {
    ...mapState('app', {
      helperInfo: (state) => state.helperInfo
    }),
    h5Link() {
      const { skillId, chapterId } = this.$route.query
      return `https://weixin.ptego.com/#/pages/courseDetail/index?id=${skillId}&chapterId=${chapterId}&taskId=${this.taskId}`
    }
  },
  methods: {
    open(item) {
      this.taskId = item.id
      this.visible = true
    },
    handleCopy() {
      copyText(this.h5Link)

      this.$message.success('复制成功')
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  color: #1f1f40;
  font-size: 14px;
  text-align: center;
  padding-bottom: 30px;

  .title {
    color: #1f1f40;
    font-size: 14px;
  }

  > img {
    width: 110px;
    height: 110px;
    margin-top: 18px;
  }

  .tip {
    color: #9e9e9e;
  }

  .link-wrap {
    text-align: left;
    margin-top: 20px;

    .ipt-wrap {
      display: flex;
      align-items: center;

      ::v-deep .el-button {
        margin-left: 10px;
        background-color: #3b86ff;
        border-color: #3b86ff;
        min-width: 100px;
      }
    }
  }
}
</style>
