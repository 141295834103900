<template>
  <div class="recommend-list">
    <div class="suggest-title">
      <span>推荐学习</span>
    </div>

    <ul class="suggest-learn-list">
      <li v-for="item of list" :key="item.id" @click="handleNavDetail(item)">
        <img :src="item.img_logo" alt="" />
        <div class="content">
          <div class="class-name">{{ item.name }}</div>
          <Label :label="item.label_id" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Label from '../components/label'
import { navCourseDetail } from '../components/utils'

import { apiCourseRecomend } from '@/api/course'

export default {
  components: {
    Label
  },
  filters: {
    lableText(val) {
      return ['', 'Speaking', 'Listening', 'Writing', 'Reading'][val]
    }
  },
  data() {
    return {
      list: []
    }
  },
  created() {
    this.fetchList()
  },
  methods: {
    async fetchList() {
      const { data } = await apiCourseRecomend()
      this.list = data
    },
    handleNavDetail(item) {
      navCourseDetail(this.$router, item)
    }
  }
}
</script>

<style scoped lang="scss">
$grayFont: #a5a5ac;
.recommend-list {
  .suggest-title {
    > span {
      font-size: 14px;
      color: #ffffff;
    }
  }
  .suggest-learn-list {
    > li {
      display: flex;
      align-items: center;
      margin-top: 16px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: translateY(-4px);
      }
      > img {
        width: 104px;
        height: 58px;
        object-fit: cover;
        border-radius: 5px;
      }
      .content {
        margin-left: 12px;
        .class-name {
          max-width: 140px;
          color: #ffffff;
          margin-bottom: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
</style>
