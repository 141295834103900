<template>
  <div class="discuss">
    <div class="forum-header">
      <img class="avatar" :src="userInfo.headimgurl" />
      <div class="isVip" v-if="userInfo.vip && userInfo.vip.code != 0">
        <img :src="require('@/assets/vip-icon.png')" alt />
      </div>
      <el-input
        type="textarea"
        :rows="3"
        v-model="input"
        placeholder="跟大家一起讨论吧"
        class="forum-input"
      ></el-input>
      <el-button
        class="sbumit-btn"
        type="primary"
        size="mini"
        @click="commentAdd"
        ><div>发表</div>
        <div>讨论</div></el-button
      >
    </div>
    <div v-loading="loading && page === 1">
      <div class="infinite-list-wrapper" v-if="list.length">
        <ul class="forum-list">
          <li
            class="forum-list-item"
            v-for="(item, index) of list"
            :key="item.id"
          >
            <img
              class="forum-list-user avatar"
              :src="item.user_info.headimgurl"
            />
            <div>
              <div>
                <span class="name">{{ item.user_info.name }}</span>
              </div>
              <div class="comment">
                <span>
                  <pre>{{ item.remark }}</pre>
                </span>
                <!--                <span v-else class="del-tips">该讨论已删除</span>-->
              </div>
              <div class="bottom">
                <span class="time">
                  {{ item.add_time }}
                </span>
                <span class="favour"
                  ><i
                    class="f-icon"
                    @click="handleLike(item)"
                    :class="[item.is_like ? 'icon-likes' : 'icon-like']"
                  />{{ item.like_cnt }}</span
                >
                <span class="reply" @click="handleReply(item, index)"
                  >回复</span
                >
              </div>
            </div>
            <ul class="reply-list">
              <li
                class="reply-list-item"
                v-for="comment of item.sub_list"
                :key="comment.id"
              >
                <img class="avatar" :src="item.user_info.headimgurl" />
                <div>
                  <div class="header">
                    <span class="name">{{ comment.user_info.name }}</span>
                    <span class="comment">
                      <pre>{{ comment.remark }}</pre>
                    </span>
                  </div>
                  <div class="bottom">
                    <span class="time">
                      {{ comment.add_time }}
                    </span>
                    <span class="favour"
                      ><i
                        class="f-icon"
                        @click="handleLike(comment)"
                        :class="[comment.is_like ? 'icon-likes' : 'icon-like']"
                      />{{ comment.like_cnt }}</span
                    >
                    <span class="reply" @click="handleReply(comment, index)">
                      回复
                    </span>
                  </div>
                </div>
              </li>
              <template v-if="item.showMore">
                <li
                  class="reply-list-item"
                  v-for="more of item.moreComments"
                  :key="more.id"
                >
                  <img :src="more.user_info.headimgurl" />
                  <div>
                    <div class="header">
                      <span class="name">{{ more.user_info.name }}</span>
                      <span class="comment">{{ more.remark }}</span>
                    </div>
                    <div class="bottom">
                      <span class="time">{{ more.add_time }}</span>
                      <span class="favour"
                        ><i
                          class="f-icon"
                          @click="handleLike(more)"
                          :class="[more.is_like ? 'icon-likes' : 'icon-like']"
                        />{{ more.like_cnt }}</span
                      >
                      <span class="reply" @click="handleReply(more, index)"
                        >回复</span
                      >
                    </div>
                  </div>
                </li>
              </template>
            </ul>
            <div
              class="reply-total"
              v-if="
                item.moreComments && item.moreComments.length && !item.showMore
              "
            >
              共{{ item.commentsTotal }}条回复，<el-button
                type="text"
                class="reply-view"
                @click="handleShowMore(index)"
                >点击查看</el-button
              >
            </div>
            <div
              class="reply-input"
              v-if="replyItem && index === replyItem.index"
            >
              <img class="avatar" :src="replyItem.user_info.headimgurl" />
              <div
                class="isVip"
                v-if="
                  replyItem.user_info.vip && replyItem.user_info.vip.code != 0
                "
              >
                <img :src="require('@/assets/vip-icon.png')" alt />
              </div>
              <el-input
                type="textarea"
                :rows="3"
                v-model="content"
                :placeholder="`回复 @${replyItem.user_info.name}：`"
                class="forum-input"
              ></el-input>
              <el-button
                class="sbumit-btn"
                type="primary"
                size="mini"
                @click="handleReplyAdd"
                ><div>发表</div>
                <div>讨论</div></el-button
              >
            </div>
          </li>
        </ul>
        <More
          :page="page"
          :pageSize="pageSize"
          :loading="loading"
          :totalPage="Math.ceil(total / pageSize)"
          @load="handleLoad"
        />
      </div>
    </div>
    <Empty v-if="!list.length && !loading" isTabs />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Empty from '@/components/ExercisesEmpty.vue'
import More from '@/components/more'

import {
  apiChapterBbsAdd,
  apiChapterBbsList,
  apiChapterLike,
} from '@/api/course'

export default {
  components: {
    Empty,
    More,
  },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    chapterId: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      input: '', // 评论内容
      page: 1,
      pageSize: 10,
      loading: false,
      list: [],
      total: 0,
      replyItem: null,
      content: '', // 回复内容
    }
  },
  computed: {
    ...mapState('app', {
      userInfo: (state) => state.userInfo,
    }),
  },
  watch: {
    chapterId: {
      immediate: true,
      handler() {
        this.list = []
        this.handleLoad(1)
      },
    },
  },
  methods: {
    handleLoad(page) {
      this.page = page

      this.fetchList()
    },
    async fetchList() {
      this.loading = true
      const {
        data: {
          list,
          pager: { total_cnt },
        },
      } = await apiChapterBbsList({
        chapter_id: this.chapterId,
        page: this.page,
        page_size: this.pageSize,
      })

      this.list = (this.page === 1 ? [] : this.list).concat(
        list.map((item) => ({
          ...item,
          showMore: false,
          sub_list: item.sub_list.slice(0, 5),
          moreComments: item.sub_list.slice(5),
        }))
      )
      this.total = total_cnt

      this.loading = false
    },
    handleShowMore(index) {
      this.list = this.list.map((m, i) => ({
        ...m,
        showMore: i === index ? true : m.showMore,
      }))
    },
    async commentAdd() {
      const { msg, code } = await apiChapterBbsAdd({
        chapter_id: this.chapterId,
        remark: this.input,
      })
      if (code == 200) {
        this.$message.success('发表成功')
        this.input = ''
        this.handleLoad(1)
        return
      }
      this.$message.success(msg)
      this.input = ''

      this.handleLoad(1)
    },
    async handleReplyAdd() {
      const { msg } = await apiChapterBbsAdd({
        chapter_id: this.chapterId,
        pid: this.replyItem.id,
        remark: this.content,
      })

      this.$message.success(msg)
      this.handleLoad(1)

      this.content = ''
      this.replyItem = null
    },
    async handleLike(item) {
      const { msg } = await apiChapterLike({
        type: 3,
        bbs_id: item.id,
      })

      this.$message.success(msg)

      this.fetchList()
    },
    handleReply(item, index) {
      this.replyItem = null
      this.content = ''
      this.replyItem = { index, ...item }
    },
  },
}
</script>

<style scoped lang="scss">
.discuss {
  margin-top: 15px;
  padding: 0 20px;
  .del-tips {
    color: #a4afb7;
  }
  .infinite-list-wrapper {
    /*border-bottom: 1px solid #ebebf2;*/
  }
  .name {
    flex-shrink: 0;
  }
  .el-button--primary {
    line-height: 17px;
    padding: 3px 8px;
  }
  .avatar {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: #eee;
    margin-right: 12px;
  }
  .forum-header {
    display: flex;
    align-items: center;
    margin-bottom: 35px;
    position: relative;
    .isVip {
      position: absolute;
      left: 24px;
      top: 28px;
      > img {
        width: 17px;
        height: 17px;
      }
    }
  }
  .reply-input {
    display: flex;
    align-items: center;
    margin: 10px;
    position: relative;
    .isVip {
      position: absolute;
      left: 24px;
      top: 28px;
      > img {
        width: 17px;
        height: 17px;
      }
    }
  }
  img {
    flex-shrink: 0;
  }
  .forum-input {
    margin-right: 7px;
    ::v-deep .el-textarea__inner {
      height: 62px;
    }
  }
  .sbumit-btn {
    height: 62px;
    width: 84px;
    font-size: 16px;
    line-height: 20px;
    background: #3b86ff;
    border-color: #3b86ff;
  }
  .forum-list {
    color: #a4afb7;
    font-size: 12px;
    .forum-list-item {
      position: relative;
      padding: 13px 0 9px;
      margin-left: 54px;
      border-top: 1px solid #676767;
      color: #b7b7b7;
      &:last-child {
        border-bottom: 1px solid #676767;
      }
      .bottom {
        > span {
          margin-right: 10px;
        }
        .favour,
        .reply {
          cursor: pointer;
        }
      }
    }
    .forum-list-user {
      position: absolute;
      left: -54px;
      top: 13px;
    }
    .comment {
      margin: 7px 0;
      color: #fff;
      font-size: 14px;
      pre {
        color: #fff;
        font-size: 14px;
      }
    }
    .reply-list {
      .reply-list-item {
        display: flex;
        align-content: flex-start;
        margin-top: 15px;
      }
      img {
        width: 28px;
        height: 28px;
      }
      .header {
        display: flex;
        line-height: 20px;
        margin-bottom: 10px;
      }
      .comment {
        margin: 0 12px 0;
      }
    }
    .del-btn {
      margin-left: 23px;
      vertical-align: bottom;
      cursor: pointer;
      &:hover {
        color: #3b86ff;
      }
    }
    .reply-total {
      .reply-view {
        font-size: 12px;
      }
    }
  }
}
.del-reply-dialog {
  .el-message-box__header {
    padding-top: 30px;
  }
  .el-message-box__content {
    padding: 0 15px;
  }
  .el-button:not(.el-button--primary) {
    color: #7c8084;
    padding: 4px 18px;
    border-radius: 5px;
    .f-icon {
      color: #7c8084;
    }
    &.active,
    &:hover {
      color: #3b86ff;
      background: #ecf3ff;
      border-color: #3b86ff;
      .f-icon {
        color: #3b86ff;
      }
    }
    &:focus {
      &.active,
      &:hover {
        color: #3b86ff;
        background: #ecf3ff;
        border-color: #3b86ff;
        .f-icon {
          color: #3b86ff;
        }
      }
      color: #7c8084;
      background: #fff;
      border: 1px solid #dcdfe6;
    }
  }
}
</style>
