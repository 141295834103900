import { post, get, getToken, postFormData } from './request'

export const apiCourseList = (p) => get('/api-web/course/course-list', p)

export const apiTeacherList = (p) => get('/api-web/course/teacher-list', p)

export const apiTeacherDetail = (p) => get('/api-web/course/teacher-detail', p)

export const apiCourseDetial = (p) => get('/api-web/course/course-info', p)

export const apiChapterList = (p) => get('/api-web/course/chapter-list', p)

export const apiCourseRecomend = (p) => get('/api-web/course/course-prom', p)

export const apiChapterDetail = (p) => get('/api-web/course/chapter-info', p)

export const apiChapterLike = (p) => get('/api-web/course/like-add', p)

export const apiPackageBuyCheck = (p) =>
  post('/api-web/course/pay-order-check', p)

export const apiChapterBbsList = (p) =>
  get('/api-web/course/chapter-bbs-list', p)
export const apiChapterBbsAdd = (p) => get('/api-web/course/chapter-bbs-add', p)

export const apiCourseCate = (p) => get('/api-web/course/tag-list', p)

export const apiCourseOrder = (p) => post('/api-web/course/pay-order-add', p)

export const apiCourseWechatPay = (p) => post('/api/pay/wx-h5-unify', p)

export const apiCourseAlipay = (p) => post('/api/pay/ali-h5-unify', p)

export const apiCoursePayCredit = (p) => post('/api/pay/pay-by-credit', p)

export const apiCourseLearn = (p) => post('/api-web/course/learn-mark', p)

export const apiCourseBuyList = (p) => get('/api-web/course/course-my-pay', p)

export const apiCourseHistoryList = (p) =>
  get('/api-web/course/course-my-view', p)

export const apiCourseVipAudio = (p) => post('/api-web/course/task-add', p)

export const apiCourseVipAudioList = (p) => get('/api-web/course/task-list', p)

export const apiCourseAudioDel = (p) => post('/api-web/course/task-del', p)

export const apiCourseTrySubmit = (p) =>
  post('/api-web/course/task-mark-add', p)

export const apiCourseJobsTry = (p) =>
  get('/api-web/question/my-chapter-try-list', p)

// 一键报名
export const apisignUp = (p) => post('/api-web/course/sign-up', p)

export const courseStat = (p) => get('/api-web/course/tag-stat', p)
