<template>
  <el-dialog
    title="提交批改"
    :visible.sync="visible"
    width="565px"
    class="practice-dialog"
  >
    <div class="msg">
      向老师提出学习过程中遇到的问题，老师将与练习批改一起进行回复答疑。
    </div>
    <el-input
      type="textarea"
      :autosize="{ minRows: 8, maxRows: 10 }"
      maxlength="1000"
      show-word-limit
      placeholder="输入你想要提问的内容"
      v-model="remark"
    ></el-input>
    <div class="bottom">
      <div class="tip">
        请注意：本练习及提问仅可提交一次，请确认无误后再提交哦
      </div>
      <el-button type="primary" size="small" @click="handleSubmit">
        提交
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import { apiCourseTrySubmit } from '@/api/course'

export default {
  data() {
    return {
      visible: false,
      courseId: 0,
      remark: ''
    }
  },
  methods: {
    open(courseId) {
      this.courseId = courseId
      this.visible = true
    },
    async handleSubmit() {
      const { code, msg } = await apiCourseTrySubmit({
        course_id: this.courseId,
        remark: this.remark
      })

      if (code !== 200) {
        this.$message.error(msg)
      }

      this.$message.success(msg)
      this.visible = false
    }
  }
}
</script>

<style scoped lang="scss">
.practice-dialog {
  .msg {
    margin-bottom: 12px;
    color: #1f1f40;
    font-size: 14px;
  }
  .bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 14px;
    .tip {
      padding: 4px 10px;
      background-color: #ffd2dd;
      color: #ff606d;
      font-size: 14px;
      border-radius: 5px;
      margin-right: 14px;
    }
    .el-button {
      width: 74px;
      height: 28px;
      background-color: #596dfa;
      border-color: #596dfa;
    }
  }
}
</style>
