<template>
  <div class="rating-criteria">
    <div class="back-title">
      <span @click="handleNavAll">所有课程</span>
      <i class="iconfont icon-class-right"></i>
      <span @click="handleNavCourse">{{ info.cate_sub_name }}</span>
      <i class="iconfont icon-class-right"></i>
      <span>{{ info.name }}</span>
    </div>

    <div class="content-wrapper">
      <div class="left">
        <div class="bannerText">{{ bannerText && bannerText.name }}</div>
        <div class="banner">
          <VideoDetail
            :info="info"
            :courseInfo="courseInfo"
            :isView="isView"
            :onPay="handlePay"
          />
          <div class="bottom">
            <div class="empty"></div>
            <div class="like" @click="handleLike">
              <i
                class="f-icon"
                :class="[is_like ? 'icon-likes' : 'icon-like']"
              ></i>
              <span>{{ info.like_cnt }}</span>
            </div>
            <div class="share" @click="handleShare">
              <i class="iconfont icon-share1"></i>
              <span>分享</span>
            </div>
          </div>
        </div>
        <div class="tabs">
          <el-tabs v-model="activeName">
            <template v-if="courseInfo.is_vip != 1">
              <el-tab-pane
                :label="type === 2 ? '课程与练习' : '课程提纲'"
                name="parctice"
              >
                <div>
                  <div class="course-info">
                    {{ info.remark }}
                  </div>

                  <Parctice
                    :isBuy="isBuy"
                    :info="info"
                    :courseInfo="courseInfo"
                    v-if="type === 2"
                    :chapterList="chapterList"
                  />
                </div>
              </el-tab-pane>
              <el-tab-pane :label="`讨论(${info.bbs_cnt})`" name="discuss">
                <Discuss :info="info" :chapterId="info.id" v-if="info.id" />
              </el-tab-pane>
            </template>
            <template v-else>
              <el-tab-pane label="练习内容" name="parctice">
                <div>
                  <div class="course-info">
                    {{ info.remark }}
                  </div>

                  <VipParctice
                    :isBuy="isBuy"
                    :courseInfo="courseInfo"
                    :info="info"
                    :chapterList="chapterList"
                  />
                </div>
              </el-tab-pane>
            </template>
          </el-tabs>
        </div>
      </div>

      <div class="right">
        <Chapter :isBuy="isBuy" v-if="type === 2" :chapterList="chapterList" />
        <Recommend v-if="courseInfo.is_vip != 1" />
      </div>
    </div>

    <Pay ref="pay" />
    <ShareCourse ref="share" />
  </div>
</template>

<script>
import Chapter from './chapter'
import Recommend from './recommend'
import Parctice from './practice'
import VideoDetail from './video'
import Discuss from './discuss'
import VipParctice from './vipParctice'
import Pay from '../components/pay'
import { mapState } from 'vuex'
import ShareCourse from '@/components/share/course'

import {
  apiChapterDetail,
  apiCourseDetial,
  apiChapterList,
  apiChapterLike
} from '@/api/course'

export default {
  components: {
    Chapter,
    Recommend,
    Parctice,
    VideoDetail,
    Discuss,
    VipParctice,
    Pay,
    ShareCourse
  },
  data() {
    return {
      info: {},
      courseInfo: {},

      activeName: 'parctice',

      chapterList: [],
      type: 2, // 2付费视频， 1单个视频
      is_like: false
    }
  },
  computed: {
    ...mapState('app', {
      userInfo: (state) => state.userInfo
    }),
    isView() {
      return this.info.free_try === 1 ? true : this.isBuy
    },
    isBuy() {
      return this.courseInfo.type === 2
        ? true
        : this.courseInfo.is_vip != 1
        ? this.courseInfo.buy_status == 1
        : this.isVip
    },
    bannerText() {
      const obj = this.chapterList.find(
        (item) => this.$route.query.chapterId == item.id
      )
      return obj
    },
    isVip() {
      try {
        return this.userInfo.vip.code == 1
      } catch (e) {
        return false
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.fetchDetail()
      }
    }
  },
  methods: {
    async fetchDetail() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      const { chapterId, skillId, type } = this.$route.query
      this.type = type || this.type
      await this.fetchChapterList(skillId, chapterId)
      await this.fetchCourseDetail(skillId)

      loading.close()
    },
    async fetchCourseDetail(skillId) {
      const { data } = await apiCourseDetial({
        course_id: skillId
      })
      this.courseInfo = data
    },
    async fetchChapterDetail(chapterId) {
      const { data } = await apiChapterDetail({
        chapter_id: chapterId
      })
      this.info = {
        ...data
      }
    },
    async fetchChapterList(skillId, chapterId) {
      const { data } = await apiChapterList({
        course_id: skillId
      })

      this.chapterList = data || []
      this.fetchChapterDetail(
        chapterId ? chapterId : ((data || [])[0] || {}).id
      )
    },

    async handleLike() {
      const { msg, code } = await apiChapterLike({
        type: 2,
        chapter_id: this.info.id
      })
      if (code == 200) this.is_like = true
      this.$message.success(msg)
      this.fetchDetail()
    },
    handlePay() {
      this.$refs.pay.open({
        name: `${this.courseInfo.cate_sub_name}: ${this.courseInfo.name}`,
        courseType: '11',
        courseIds: [this.courseInfo.id],
        money_total: this.courseInfo.money,
        money_ticket_total: this.courseInfo.money_ticket,
        rebate: 1
      })
    },

    handleNavAll() {
      this.$router.push({
        name: 'courseAllList'
      })
    },
    handleNavCourse() {
      this.$router.push({
        name: 'course',
        query: {
          cateMain: this.courseInfo.cate_main,
          cateSub: this.courseInfo.cate_sub
        }
      })
    },

    handleShare() {
      this.$refs.share.open()
    }
  }
}
</script>

<style lang="scss" scoped>
$grayFont: #a5a5ac;

.rating-criteria {
  font-size: 14px;
  width: 1200px;
  margin: 0 auto;
  padding-top: 25px;
  padding-bottom: 100px;
  .back-title {
    font-size: 14px;
    color: #a5a5ac;
    display: flex;
    padding-top: 10px;
    align-items: center;

    > span {
      cursor: pointer;
      &:last-child {
        cursor: none;
        color: #ffffff;
      }

      margin-right: 5px;
    }

    > i {
      margin-right: 5px;
    }
  }

  .content-wrapper {
    display: flex;

    .left {
      flex: 1;
      .bannerText {
        font-size: 20px;

        font-weight: 400;
        line-height: 24px;
        color: #ffffff;
        margin-top: 23px;
      }
      .banner {
        margin-top: 15px;
        background-color: #5c5c69;
        border-radius: 5px;
        overflow: hidden;

        .bottom {
          height: 38px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: $grayFont;
          .empty {
            flex: 1;
          }
          .like {
            margin-right: 26px;
            cursor: pointer;
            > i {
              margin-right: 3px;
            }
          }
          .share {
            margin-right: 14px;
            cursor: pointer;
            > i {
              margin-right: 3px;
            }
          }
        }
      }

      .course-info {
        color: $grayFont;
        line-height: 20px;
        margin-top: 16px;
        margin-bottom: 24px;
      }

      .tabs {
        ::v-deep .el-tabs__nav-wrap {
          &:after {
            background-color: #676767;
          }
          .el-tabs__nav {
            .el-tabs__active-bar {
              background-color: #ff9800;
            }

            .el-tabs__item {
              color: #fff;

              &.is-active {
                color: #ff9800;
              }
            }
          }
        }
      }
    }

    .right {
      width: 260px;
      margin-top: 28px;
      margin-left: 45px;
    }
  }
}
</style>
